import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Realisations from "../components/Realisations"

const SecondPage = () => (
  <Layout>
    <SEO title="Réalisations"/>
    <Realisations/>
  </Layout>
)

export default SecondPage

import React from 'react'
import { withStyles, Card, CardHeader, CardMedia, CardContent, Typography } from '@material-ui/core'
import _ from 'lodash'
import PropTypes from 'prop-types'

const styles = theme => ({
  card: {
    maxWidth: 345,
    backgroundColor: '#222',
    border: '1px solid black',
    '&:hover': {
      borderColor: theme.palette.primary.main
    }
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
    paddingLeft: '10px',
    paddingRight: '10px',
    filter: 'grayscale(100%)',
    '&:hover': {
      filter: 'none'
    }
  },
  text: {
    color: theme.palette.text.secondary
  },
  link: {
    textDecoration: 'none'
  },
  title: {
    color: theme.palette.text.primary
  },
  technoContainer: {
    marginTop: '10px',
    display: 'flex',
    justifyContent: 'space-between'
  },
  techno: {
    '&:hover': {
      color: 'red'
    }
  }
})

const RealisationCard = props => {
  const { image, title, description, url, techno, classes } = props

  return (
    <Card className={classes.card}>
      <a href={url} rel="noopener noreferrer" target={'_blank'} className={classes.link}>
        <CardHeader
          className={classes.title}
          title={title}
          subheader={url}
        />
        <CardMedia
          className={classes.media}
          image={image}
          title={title}
        />
        <CardContent>
          <Typography className={classes.text} variant="body2" color="textSecondary" component="p">
            {description}
          </Typography>
          <div className={classes.technoContainer}>
            {_.map(techno, (t, id) => {
              return (
                <Typography key={id} className={classes.text} variant="body2" color="textSecondary" component="p">
                  {t}
                </Typography>
              )
            })}
          </div>
        </CardContent>
      </a>
    </Card>
  )
}

RealisationCard.propTypes = {
  classes: PropTypes.object.isRequired,
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  techno: PropTypes.array.isRequired
}

export default (withStyles(styles))(RealisationCard)

import React from "react"
import AtelierFranck66 from "../images/atelierfranck66.jpg"
import RealisationCard from "./RealisationCard"
import _ from "lodash"
import { withStyles, Grid, Typography } from "@material-ui/core"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"

const styles = theme => ({
  container: {
    [theme.breakpoints.down("xs")]: {
      justifyContent: "center",
    },
  },
  title: {
    color: theme.palette.text.primary,
    marginTop: 0,
    marginBottom: 20,
    fontWeight: "bold",
    fontSize: "30px",
    textShadow: "-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black",
    [theme.breakpoints.down("xs")]: {
      textAlign: "center",
    },
  },
})

const Realisations = props => {
  const { classes } = props
  const [t] = useTranslation("RealisationsPage")
  const realisations = [
    {
      title: t("atelierfranck66.title"),
      image: AtelierFranck66,
      url: "https://www.atelierfranck66.fr",
      description: t("atelierfranck66.description"),
      techno: ["React", "Gatsby", "Strapi", "Material-UI"],
    },
  ]

  return (
    <Grid data-sal="fade" container className={classes.container}>
      <Typography className={classes.title}>{t("title")}</Typography>
      <Grid container item xs={12} className={classes.container}>
        {_.map(realisations, (realisation, id) => (
          <RealisationCard
            key={id}
            title={realisation.title}
            description={realisation.description}
            image={realisation.image}
            url={realisation.url}
            techno={realisation.techno}
          />
        ))}
      </Grid>
    </Grid>
  )
}

Realisations.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(Realisations)
